import qs from "qs"
import { getJWT } from "../hooks/useAuth"
const baseURL = process.env.GATSBY_STRAPI_API_URL
const authUrl = `${baseURL}/api/auth/local`
const forgotUrl = `${baseURL}/api/auth/forgot-password`
const resetUrl = `${baseURL}/api/auth/reset-password`
const usersUrl = `${baseURL}/api/users`

export const createAccount = async (accountDetails) => {

    const response = await fetch(`${authUrl}/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(accountDetails)
    })
    return response.json()
}

export const fetchUser = async () => {
    const query = qs.stringify({
        populate: {
            personalDetails: {
                populate: ["emergencyContact", "address"]
            }
        }
    })
    const response = await fetch(`${usersUrl}/me?${query}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        }
    })
    return response.json()
}

export const sendForgotPassword = async (email) => {
    const response = await fetch(`${forgotUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({email})
    })
    return response.json()
}

export const resetPassword = async (newDetails) => {
    const response = await fetch(`${resetUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newDetails)
    })
    return response.json()
}