import React, { useState } from 'react'
import { navigate } from 'gatsby'
import useAuth from '../hooks/useAuth'
import { resetPassword } from '../backendApi/userApi'

const ResetPassword = ({ code }) => {
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    const obj = {
      code, // code contained in the reset link of step 3.
      password,
      passwordConfirmation,
    };
    (async () => {
      const res = await resetPassword(obj)
      if (res) {
        alert('Your password has been reset. Please login')
        navigate('/login')
      } else {
        alert('Something went wrong. Double check the code in the URL is correct')
      }
    })()
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}>
        <div>
          <label htmlFor="password">
            New Password:
          </label>
          <input
            onChange={e => {
              setPassword(e.target.value)
            }}
            value={password}
            id="password" type="password" placeholder="******************" />
        </div>
        <div>
          <label htmlFor="passwordConfirmation">
            Enter your new password again:
          </label>
          <input
            onChange={e => {
              setPasswordConfirmation(e.target.value)
            }}
            value={passwordConfirmation}
            id="passwordConfirmation" type="password" placeholder="******************" />
        </div>
        {
          (password !== passwordConfirmation && passwordConfirmation.length > 0) ?
            <p>
              Your new password and the password confirmation are different
            </p>
            :
            <div>
              <button type="submit">
                Reset Password
              </button>
            </div>
        }
      </form>
    </div>
  )
}

export default ResetPassword
