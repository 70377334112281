import React from "react"
import { Link, navigate } from "gatsby"
import ResetPassword from '../components/ResetPassword'

import Layout from "../components/layout"
import NavBar from "../components/navBar/navBar"
import useAuth from "../hooks/useAuth"

const ResetPasswordPage = ({ location }) => {
    const { isAuthenticated } = useAuth()
    if (isAuthenticated) {
        navigate('/')
    }

    const code = location.search.substring(6) || null

    return (
        <Layout>
            <NavBar />
            <h1>Reset Password</h1>
            <p>Please enter your new password</p>
            <div>
                <ResetPassword code={code}/>
            </div>

        </Layout>
    )
}

export default ResetPasswordPage
