import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import * as styles from "./navBar.module.css"
import useAuth from "../../hooks/useAuth"

const pageLinks: Array<Object> = [
  {
    text: "Home",
    url: "/",
    description: "WSG Home",
  },
  {
    text: "About Us",
    url: "/about-us",
    description: "About the history of the WSG",
  },
  {
    text: "Membership",
    url: "/membership",
    description: "Information about joining the club",
  },
  {
    text: "Club Events",
    url: "/club-events",
    description: "The WSG calendar and events",
  },
  {
    text: "Caving Hut",
    url: "/caving-hut",
    description: "Hut information and booking info",
  },
  {
    text: "Trip Reports",
    url: "/trip-reports",
    description: "The trip reports club members have written",
  },
  {
    text: "Albums",
    url: "/albums",
    description: "Albums coming soon...",
  },
]

const yourProfileLink = {
  text: "Your Profile",
  url: "/app/your-profile",
  description: "See and update your logged in profile"
}

const loginLink = {
  text: "Login",
  url: "/login",
  description: "Login to see and edit member only content",
}

const logoutLink = {
  text: "Logout",
  url: "/logout",
  description: "Logout from account",
}


export default function NavBar(): JSX.Element {
  const { logout, isAuthenticated } = useAuth()
  const [navbarLinks, setNavbarLinks] = useState(pageLinks)

  useEffect(() => {
    if (isAuthenticated) {
      setNavbarLinks([...pageLinks, yourProfileLink])
    }
    else {
      setNavbarLinks([...pageLinks, loginLink])
    }
  }, [isAuthenticated])

  const handleLogout = (e) => {
    e.preventDefault()
    logout()
  }

  return (
    <div className={styles.textCenter}>
      <StaticImage
        src="./wsglogo_medium.png"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: "var(--space-3)" }}
      />
      <h1>Westminster Speleological Group</h1>
      <p className={styles.navbar}>
        {navbarLinks.map((link, i) => (
          <React.Fragment key={link.url}>
            <Link to={link.url}>{link.text}</Link>
            {i !== navbarLinks.length - 1 && <> · </>}
          </React.Fragment>
        ))}
        { !!isAuthenticated && 
          (
            <><> · </><a
            onClick={handleLogout}
            href="/">
            Logout
          </a></>
          )
        }
        <br />
      </p>
    </div>
  )
}
